import React from 'react';

function NavTop() {
  return (
    <div className="nav-top pt-30 pb-30">
      <div className="container">
        <div className="row">
          <div className="col-md-4 valign">
            {/* <a href="#0" className="logo icon-img-60">
              Fauzi Halabe
            </a> */}
          </div>
          <div className="col-md-4 valign">
            <div className="social text-center full-width">
              {/* <a href="#0">
                <i className="fab fa-behance"></i>
              </a> */}
               <a target='_blank' href="https://w.app/fauzihalabe">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a target='_blank' href="https://github.com/fauzihalabe">
                <i className="fab fa-github"></i>
              </a>
              <a target='_blank' href="https://www.linkedin.com/in/fauzihalabe">
                <i className="fab fa-linkedin-in"></i>
              </a>
              {/* <a href="#0">
                <i className="fab fa-dribbble"></i>
              </a> */}
            </div>
          </div>
          <div className="col-md-4 valign">
            <div className="full-width info">
              <div className="d-flex justify-content-end">
                <a href="mailto:oi@fauzihalabe.dev">
                  <span className="sub-title fz-12">oi@fauzihalabe.dev</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-butn">
          <span className="pe-7s-menu"></span>
        </div>
      </div>
    </div>
  );
}

export default NavTop;

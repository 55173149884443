import React from 'react';

function Info() {
  return (
    <div className="sec-box contact section-padding bord-thin-top" id="info">
      <div className="row">
        <div className="col-lg-12">
          <div className="sec-head md-mb80 wow fadeIn">
            <h6 className="sub-title mb-15 opacity-7">Fale comigo</h6>
            <h2 className="fz-50">Vamos desenvolver um projeto brilhante!</h2>
            {/* <p className="fz-15 mt-10">
              If you would like to work with us or just want to get in touch,
              we’d love to hear from you!
            </p> */}

            <ul className="rest social-text d-flex mt-60">
              <li className="mr-30">
                <a target='_blank' href="https://w.app/fauzihalabe">
                  <i className="fab fa-whatsapp"></i>
                  {' '}
                  Whatsapp
                </a>
              </li>

            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Info;

import React from 'react';

function Profile() {
  return (
    <section id="home" className="intro-profile md-mb50">
      <div className="row rest">
        <div className="col-lg-4 box-img main-bg">
          <div className="cont valign">
            <div className="full-width">
              <div className="img">
                <img src="/assets/imgs/header/profile.jpg" alt="" />
                <span className="icon">
                  <img src="/assets/imgs/header/cloud.png" alt="" />
                </span>
                <span className="icon">
                  <img src="/assets/imgs/header/icon2.png" alt="" />
                </span>
                <span className="icon">
                  <img src="/assets/imgs/header/js.png" alt="" />
                </span>
              </div>
              <div className="info text-center mt-30">
                <h5>Fauzi Halabe</h5>
                <p className="fz-13 text-u">Full stack developer</p>
              </div>
              <div className="social text-center mt-20">
                {/* <a href="#0">
                  <i className="fab fa-behance"></i>
                </a> */}
                <a target='_blank' href="https://w.app/fauzihalabe">
                  <i className="fab fa-whatsapp"></i>
                </a>
                <a target='_blank' href="https://github.com/fauzihalabe">
                  <i className="fab fa-github"></i>
                </a>
                <a target='_blank' href="https://www.linkedin.com/in/fauzihalabe">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                {/* <a href="#0">
                  <i className="fab fa-dribbble"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 content main-bg">
          <h1>
            Olá, eu sou o <span className="main-color">Fauzi Halabe</span>. Desenvolvedor
            {' '}
            <span className="bord">
              full stack senior<i></i>
            </span>{' '}
            e system architect
          </h1>
          <div className="stauts mt-80">
            <div className="d-flex align-items-center">
              <div className="mr-40">
                <div className="d-flex align-items-center">
                  <h2>10+</h2>
                  <p>
                    anos de experiência
                  </p>
                </div>
              </div>
              <div className="mr-40">
                <div className="d-flex align-items-center">
                  <h2>100+</h2>
                  <p>
                    projetos desenvolvidos
                  </p>
                </div>
              </div>
              <div>
                <div className="butn-presv">
                  <a target='_blank' href="https://w.app/fauzihalabe" className="butn butn-md butn-bord radius-5 skew">
                    <span>Whatsapp</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;

import React from 'react';
import { Link } from 'react-scroll';
function Navbar() {
  return (
    <nav className="navbar">
      <div className="row justify-content-end rest">
        <div className="col-lg-8 rest">
          <ul className="navbar-nav main-bg d-flex justify-content-end">
            <li className="nav-item">
              <Link to="services" spy={true} smooth={true}>
                <span>Serviços</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="home" spy={true} smooth={true}>
                <span>Sobre</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="portfolio" spy={true} smooth={true}>
                <span>Portfólio</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="testimonials" spy={true} smooth={true}>
                <span>Depoimentos</span>
              </Link>
            </li>
      
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

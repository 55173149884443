import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper';

const ScreenshotSlider = () => {
  useEffect(() => {
    new Swiper('.miwlo-app-screenshot-slide', {
      modules: [Navigation],
      paginationClickable: true,
      effect: 'coverflow',
      lazyLoadingInPrevNext: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 100,
        depth: 350,
        modifier: 1,
        slideShadows: true,
      },
    });
  }, []);

  return (
    <div className="miwlo-app-screenshot-wrap">
      <div className="container">

        <div className="row text-center">
          <div className="col-md">
            <div className="miwlo-app-screenshot-preview" data-aos="fade-up" data-aos-delay="400">
              <div className="app-screenshot-mobile-frame">
                <img src="/assets/imgs/slider-one/mobile-frame.png" alt="Mobile Frame" />
              </div>
              {/* Swiper */}
              <div className="swiper-container miwlo-app-screenshot-slide">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/1.jpg" alt="slide 1" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/2.jpg" alt="slide 2" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/3.jpg" alt="slide 3" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/4.jpg" alt="slide 4" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/5.jpg" alt="slide 5" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/6.jpg" alt="slide 6" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/7.jpg" alt="slide 6" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/8.jpg" alt="slide 6" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/9.jpg" alt="slide 6" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/10.jpg" alt="slide 6" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="slider-image">
                      <img src="/assets/imgs/slider-one/11.jpg" alt="slide 6" />
                    </div>
                  </div>
                </div>
                {/* Pagination */}
                <div className="swiper-pagination"></div>
              </div>
              {/* Navigation Arrows */}
              <div className="swiper-next">
                <i className="fas fa-arrow-right"></i>
              </div>
              <div className="swiper-prev">
                <i className="fas fa-arrow-left"></i>
              </div>



            </div>
          </div>
        </div>
      </div>

 

    </div>


  );
};

export default ScreenshotSlider;
